<template>
  <div>
    <div class="title mt-2 mb-2">
      Riwayat Tiket
    </div>
    <BRow>
      <BCol lg="6">
        <BOverlay
          :show="loadingDataChat"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <div class="history p-1 mb-1 space-y-7">
            <div
              v-for="(item, idx) in data.history_ticket"
              :key="idx"
              class="flex space-x-3"
              :class="item.type === 'Partner' || item.type === 'Mitra' ? 'history-left' : (item.type === 'Admin' ? 'history-right' : '')"
            >
              <img
                v-if="item.type === 'Partner' || item.type === 'Mitra'"
                :src="item.image_path !== '' ? item.image_path : 'https://storage.googleapis.com/komerce/assets/icons/profile-placehold.svg'"
                class="w-10 h-10 rounded-full border border-primary"
                alt="profile"
              >

              <div class="w-full">
                <div
                  v-if="item.type !== 'Shipping' && item.type !== 'Change Status'"
                  class="sender"
                >
                  <span class="visible-to">{{ item.visible_to ? `(${item.visible_to})` : `` }}</span> {{ item.name }}
                </div>
                <div
                  v-if="item.message !== '' && item.message !== null"
                  :class="item.type === 'Partner' || item.type === 'Mitra' ? 'history-left-card' : (item.type === 'Admin' ? 'history-right-card' : '')"
                >
                  <div
                    v-if="item.type === 'Partner' || item.type === 'Mitra' || item.type === 'Admin'"
                    v-html="messageAdapter(item.message)"
                  />
                  <div
                    v-else-if="item.type === 'Shipping'"
                    class="flex align-items-center justify-content-center space-x-2 w-full"
                  >
                    <hr style="height:0.7px;min-width:5%;color:gray;background-color:gray">
                    <div class="w-full text-center flex bg-[#E2E2E2] px-[10px] py-[5px] rounded-[32px] space-x-[8px]">
                      <img
                        v-if="item.type === 'Shipping'"
                        src="https://storage.googleapis.com/komerce/assets/icons/resi-kirim.svg"
                        alt="Komerce"
                        width="24"
                        height="24"
                      >
                      <div class="text-shipping">
                        {{ item.message }}
                      </div>
                    </div>
                    <hr style="height:0.7px;min-width:5%;color:gray;background-color:gray">
                  </div>
                  <div
                    v-if="item.type === 'Shipping'"
                    class="time-shipping"
                  >
                    {{ FULL_DATE_TIME(item.date_created) }}
                  </div>
                </div>
                <div
                  v-for="(file, index) in item.file"
                  :key="index"
                >
                  <video
                    v-if="handelregexvideo(file.path)"
                    width="320"
                    height="240"
                    controls
                    :class="item.type === 'Partner' ? 'img-partner' : 'img-mitra'"
                  >
                    <source
                      :src="file.path"
                      type="video/mp4"
                    >
                  </video>
                  <a
                    v-if="handleregeximage(file.path)"
                    :href="file.path"
                    target="_blank"
                  >
                    <img
                      width="220"
                      height="140"
                      :src="file.path"
                      :class="item.type === 'Partner' ? 'img-partner' : 'img-mitra'"
                    >
                  </a>
                  <a
                    v-if="handleregexdoc(file.path)"
                    :href="file.path"
                    download
                    style="margin-top: 10px; margin-bottom: 10px; display: block"
                  >
                    {{ handlecuturl(file.path) }}
                  </a>
                </div>
                <div
                  v-if="item.type !== 'Shipping' && item.type !== 'Change Status'"
                  class="time"
                >
                  {{ FULL_DATE_TIME(item.date_created) }}
                </div>
                <div
                  v-if="item.type === 'Change Status'"
                  class="my-[12px] text-[10px]"
                >
                  <div class="flex align-items-center justify-content-center space-x-2">
                    <hr style="height:0.7px;min-width: 5%;color:gray;background-color:gray">
                    <div
                      class="d-flex align-items-center justify-content-center w-full"
                      style="background: #E2E2E2;
                                          border-radius: 32px;padding-left: 10px; padding-top: 5px;padding-bottom: 5px;padding-right: 10px;"
                    >
                      <span
                        class="mr-1"
                        style="color: #222222"
                      >
                        {{ item.name }} mengubah Status
                      </span>
                      <div
                        class="mr-50"
                        style="width: 14px;
                                              height: 14px;
                                              background: #F65A5C;
                                              border-radius: 4px;"
                      />
                      <span style="color: #222222; font-weight: 700;">
                        {{ item.message_change_status.from }}
                      </span>
                      <span
                        class="mx-50"
                        style="color: #222222"
                      >ke</span>
                      <div
                        class="mr-50"
                        style="width: 14px;
                                              height: 14px;
                                              background: #50A0FF;
                                              border-radius: 4px;"
                      />
                      <span style="color: #222222; font-weight: 700;">
                        {{ item.message_change_status.to }}
                      </span>
                    </div>
                    <hr style="height:0.7px;min-width: 5%;color:gray;background-color:gray">
                  </div>
                  <div class="time-shipping">{{ FULL_DATE_TIME(item.date_created) }}</div>
                </div>
              </div>
            </div>
          </div>
        </BOverlay>
      </BCol>
      <BCol lg="6">
        <BCard class="p-0 history">
          <div class="flex p-0">
            <strong
              class="text-black font-bold text-[16px] mr-1"
            >Nilai Transaksi</strong>
            <img
              v-b-tooltip.hover.top="'Nilai total yang pembeli harus bayar'"
              src="https://storage.googleapis.com/komerce/assets/svg/info-circle.svg"
              alt="Komerce"
            >
          </div>
          <span
            class="text-[24px] text-green-500 font-bold"
          >Rp. {{ formatCurrency(data.grand_total) }}</span>
          <hr>
          <BOverlay
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <HistoryShipping :awb="data.no_resi" />
          </BOverlay>
        </BCard>
      </BCol>
    </BRow>
    <BRow>
      <BCol
        cols="12"
      >
        <BInputGroup
          class="p-[12px]"
          style="border: 1px solid #E2E2E2;
                        border-radius: 8px;"
        >
          <div
            class="w-full grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-2"
            :class="itemsImageInitialFile.length > 0 ? `border-b` : ``"
          >
            <div
              v-for="(itemFile, index) in itemsImageInitialFile"
              :key="index+1"
            >
              <b-col md="auto">
                <b-row class="align-items-top">
                  <a
                    :href="fileUrl(itemFile)"
                    target="_blank"
                    class="ml-1"
                  >
                    <b-img
                      v-if="itemFile.name.slice(itemFile.name.length - 3) !== 'mp4' && !itemFile.type.includes('application')"
                      class="class__file__chat m-1"
                      :src="fileUrl(itemFile)"
                    />
                    <video
                      v-if="itemFile.name.slice(itemFile.name.length - 3) === 'mp4'"
                      v-show="itemFile.name"
                      id="video-preview"
                      controls
                      :src="fileUrl(itemFile)"
                      width="320"
                      height="240"
                      class="m-1"
                    />
                    <div
                      v-if="itemFile.type.includes('application')"
                      class="ml-1 mr-1"
                      style="
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    width: 132px;
                                                    height: 62px;
                                                    background: #E8E8E8;
                                                    border-radius: 12px;
                                                "
                    >
                      <b-img src="@/assets/images/icons/icon-prev-file.svg" />
                    </div>
                  </a>
                  <div>
                    <b-button
                      class="btn-icon"
                      variant="flat-dark"
                      size="sm"
                      style="border: 1px solid #222222; border-radius: 50%; padding: 2px;"
                      @click="removeFileChat(itemFile)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </b-row>
                <div class="pb-1">
                  <span class="text-black ml-1">
                    {{ itemFile.name }}
                  </span>
                </div>
              </b-col>
            </div>
          </div>
          <template #append>
            <b-row class="align-items-end pr-[12px] gap-[10px]">
              <div style="">
                <label
                  for="fileChat"
                  class="btn btn-outline-primary btn-icon mb-0"
                >
                  <b-img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/directbox-send.svg"
                    style="height: 16px;"
                  />
                </label>
                <b-form-file
                  v-if="chatFileMode"
                  id="fileChat"
                  v-model="imageFile"
                  class="d-none"
                  multiple
                  @change="addFileChat"
                />
                <b-form-file
                  v-else
                  id="fileChat"
                  class="d-none"
                  multiple
                  @change="putFileChat"
                />

              </div>
              <b-dropdown
                size="md"
                dropup
                variant="outline-primary"
                :text="selectedOption === 0 ? 'Semua' : selectedOption === 1 ? 'Partner' : 'Ekspedisi'"
              >
                <b-dropdown-item
                  :active="selectedOption === 0"
                  @click="selectedOption = 0"
                >
                  Semua
                </b-dropdown-item>
                <b-dropdown-item
                  :active="selectedOption === 2"
                  @click="selectedOption = 2"
                >
                  Ekspedisi
                </b-dropdown-item>
                <b-dropdown-item
                  :active="selectedOption === 1"
                  @click="selectedOption = 1"
                >
                  Partner
                </b-dropdown-item>
              </b-dropdown>
              <div>
                <b-button
                  v-if="(message !== '' || itemsImageInitialFile.length > 0) && loadingDataChat === false"
                  variant="primary"
                  size="sm"
                  @click="storeChat"
                >
                  <b-img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/send.svg"
                  />
                </b-button>
                <b-button
                  v-else
                  variant="outline-primary"
                  size="sm"
                  :disabled="true"
                >
                  <b-img src="https://storage.googleapis.com/komerce/assets/icons/send-chat-active.svg" />
                </b-button>
              </div>
            </b-row>
          </template>
          <BFormTextarea
            v-model="message"
            no-resize
            rows="6"
            class="border-0 mr-[15px]"
            placeholder="Ketik pesan"
            @keydown.enter="handleEnterKey"
          />
        </BInputGroup>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { FULL_DATE_TIME } from '@/libs/filterDate'
import HistoryShipping from './HistoryShipping.vue'

export default {
  name: 'HistoryTiket',
  components: {
    BCard,
    BRow,
    BCol,
    HistoryShipping,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    handleregeximage: {
      type: Function,
      required: true,
    },
    handleregexdoc: {
      type: Function,
      required: true,
    },
    handelregexvideo: {
      type: Function,
      required: true,
    },
    handleextension: {
      type: Function,
      required: true,
    },
    handlecuturl: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      itemAwb: [],
      loading: true,
      loadingDataChat: false,
      FULL_DATE_TIME,
      listAwb: '',

      message: '',

      chatFileMode: false,
      itemsImageInitialFile: [],
      imageFile: null,

      selectedOption: 0,
    }
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value)
    },
    onChangeFile(event) {
      event.target.files.forEach(this.myFile)
    },
    myFile(data) {
      this.itemsImageInitialFile.push(data)
    },
    putFileChat(event) {
      event.target.files.forEach(this.myFile)
      this.chatFileMode = true
    },
    addFileChat(event) {
      event.target.files.forEach(this.myFile)
    },
    removeFileChat(data) {
      const findIndexObj = this.itemsImageInitialFile.findIndex(items => items.name === data.name)
      this.itemsImageInitialFile.splice(findIndexObj, 1)
      if (this.itemsImageInitialFile.length === 0) {
        this.chatFileMode = false
      }
    },
    fileUrl: file => (file ? URL.createObjectURL(file) : null),
    async storeChat() {
      this.loadingDataChat = true
      const formData = new FormData()
      formData.append('message', this.message)
      formData.append('is_visible', this.selectedOption)
      formData.append('ticket_id', Number(this.$route.params.id))
      if (this.itemsImageInitialFile.length > 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.itemsImageInitialFile.length; i++) {
          formData.append('file[]', this.itemsImageInitialFile[i])
        }
      } else {
        formData.append('file[]', this.itemsImageInitialFile[0])
      }
      this.$http_komship.post('/v1/ticket-admin/store-chat', formData)
        .then(() => {
          this.$emit('executeParentMethod')
          this.message = ''
          this.itemsImageInitialFile = []
          this.selectedOption = 0
          this.loadingDataChat = false
          this.chatFileMode = false
        }).catch(() => {
          this.loadingDataChat = false
        })
    },

    handleEnterKey(event) {
      if (this.message === '') {
        event.preventDefault()
      }
    },

    messageAdapter(chat) {
      let link = null
      const convertToBoolean = text => {
        const anchorRegex = /<a\s+(?:[^>]*?\s+)?href=['"](.*?)['"][^>]*?>\[Lihat Bukti\]<\/a>/
        return anchorRegex.test(text)
      }
      const isProof = convertToBoolean(chat)
      if (typeof (chat) !== 'object' && !isProof) {
        const urlify = text => {
          const urlRegex = /(https?:\/\/[^\s]+)/g
          return text.replace(urlRegex, url => `<a href="${url}" target="_blank" class="text-white">${url}</a>`)
        }
        link = urlify(chat)
      } else {
        link = chat
      }
      return link
    },
  },
}
</script>

<style lang src="./HistoryTiket.scss" scoped />
